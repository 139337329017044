'use strict';

var angular = require('angular');

angular.module('app').controller('authController', require('./authController'));
angular.module('app').controller('dashboardController', require('./dashboardController'));
angular.module('app').controller('feedbackController', require('./feedbackController'));
angular.module('app').controller('instanceController', require('./instanceController'));
angular.module('app').controller('navController', require('./navController'));
angular.module('app').controller('userController', require('./userController'));
angular.module('app').controller('manualController', require('./manualController'));
angular.module('app').controller('serverLogController', require('./serverLogController'));
angular.module('app').controller('changeFtpPasswordModalController', require('./changeFtpPasswordModalController'));
angular.module('app').controller('instanceBandWidthModalController', require('./instanceBandWidthModalController'));
