'use strict';

Users.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Users ( $rootScope, $http, API ) {
  var _getUser = function(userId) {
    var url = API + '/api/users/' + userId;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(user_id, params) {
    var url = API + '/api/users/' +  user_id;
    return $http({
      method: 'put',
      url: url,
      data: params
    });
  };

  var _changePassword = function(id, params) {
    var url = API + '/api/users/' + id + '/changePassword';
    return $http({
      method: 'put',
      url: url,
      data: params
    });
  };

  return {
    getUser: function(userId) {
      return _getUser(userId);
    },
    save: function(user_id, params) {
      return _save(user_id, params);
    },
    changePassword: function(id, params) {
      return _changePassword(id, params);
    }
  };
};

module.exports = Users;
