'use strict';

changeFtpPasswordModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Instances',
  'params'
];

function changeFtpPasswordModalController($uibModalInstance, $scope, $uibModal, Instances, params) {

  $scope.instanceHostname = params.instanceHostname;
  $scope.instanceId = params.instanceId;
  $scope.user = params.user;

  // OK
  $scope.change = function() {

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "FTP パスワードの変更";
        $scope.messageBody = "FTP パスワードを変更します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var param = {
        user: $scope.user,
        password: $scope.newPassword
      };
      Instances.resetFtpPassword($scope.instanceId, param).then(function(data) {
        modal.close();
        if (data.message == undefined) {
          $uibModalInstance.close(data);
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function() {});
  };

  // Cancel
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
};

module.exports = changeFtpPasswordModalController;
