'use strict';

instanceBandWidthModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Instances',
  'params'
];

function instanceBandWidthModalController($uibModalInstance, $scope, $uibModal, Instances, params) {

  $scope.instanceHostname = params.instanceHostname;
  $scope.instanceId = params.instanceId;

  const GiB = 1024 * 1024 * 1024;
  const TiB = 1024 * GiB;

  const _is_private = function ( instance ) {
    const seller = $scope.instance.order.seller;
    return seller.sellerName == 'プライベート' ? true : false;
  }

  const plan = $scope.instance.instanceDetail.instanceDetailPlan;
  const freetier = _freeBandwidthByPlan( plan );
  $scope.freetier = _is_private( $scope.instance ) ? '' : `転送量枠: ${ Number(( freetier / TiB ).toFixed(1)).toLocaleString('ja') } TiB / ${ Number(( freetier / GiB ).toFixed(0)).toLocaleString('ja') } GiB`;

  var param = {
    annual: 'half'
  };

  Instances.getNetworkBandWidth($scope.instanceId, param).then(function(data){
    if (data.message == undefined) {
      $scope.bandwidthList = [];
      for ( const bw of data.bandwidth ) {
        bw.bandwidth = `${ Number( ( bw.raw / GiB ).toFixed(2)).toLocaleString('ja') } GiB`;
        const excess = ( bw.raw > freetier ) ? bw.raw - freetier : 0;
        if (excess > 0) {
          bw.excess = `${ Math.ceil( excess / GiB ).toLocaleString('ja') } GiB 超過`;
        } else {
          bw.excess = '';
        }
        $scope.bandwidthList.push( bw );
      }
    } else {
      $scope.messages.status = 'danger';
      $scope.messages.message = data.message;
    }
  });

  // Close
  $scope.close = function() {
    $uibModalInstance.dismiss();
  };
};

const _freeBandwidthByPlan = function ( plan ) {
  const GiB = 1024 * 1024 * 1024;
  const TiB = 1024 * GiB;
    
  var bandwidth; 
  if ( plan.match( /^(S[12]|M3)/ ) ) {
    bandwidth = 1 * TiB; 
  } else if ( plan.match( /^(S4|M4)/ ) ) {
    bandwidth = 2 * TiB;
  } else if ( plan.match( /^(M16|L8|L16|XL16)/ )) {
    bandwidth = 3 * TiB;
  }
  return bandwidth;
}

module.exports = instanceBandWidthModalController;
