  'use strict';

manualController.$inject = [
  '$scope',
  '$sessionStorage',
  '$routeParams',
]

function manualController($scope, $sessionStorage, $routeParams) {

}

module.exports = manualController;
