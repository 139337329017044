'use strict';

navController.$inject = [
  '$scope',
  '$sessionStorage',
  '$location'
];

function navController($scope, $sessionStorage, $location) {
  if ( $location.path() == "/signin") {
    $scope.isNavBarVisible = false;
  }

  $scope.$watch(
    function(){
      return $sessionStorage.user
    },
    function(newVal, oldVal){
      if ( newVal ) {
        $scope.displayName = newVal.accountDisplayName;
        $scope.isNavBarVisible = true;
      }
      else {
        $scope.isNavBarVisible = false;
      }
    }
  );
};

module.exports = navController;
