'use strict';

Products.$inject = [
  '$http',
  'API'
];

function Products( $http, API ) {
  var _loadProducts = function(orderId) {
    var url = API + '/api/products/updatable';
    return $http({
      method: 'get',
      url: url,
      params: {
        orderId: orderId
      }
    });
  };

  return {
    listProducts: function(orderId) {
      return _loadProducts(orderId).then(function(res) {
        return res.data;
      });
    }
  };
};

module.exports = Products;
