'use strict';

serverLogController.$inject = [
  '$scope',
  '$routeParams',
  '$uibModal',
  '$q',
  '$location',
  'Instances',
]

function serverLogController($scope, $routeParams, $uibModal, $q, $location, Instances) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Reload specific log
  var _reload = function(type) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    $q.all([
      _loadServerLog(type)
    ])
    .then(function(){
      modal.close();
    })
  };

  //////////////////////////////////////////////////////////////////////
  // Back to instance detail.
  var _back = function() {
    $location.path('/instances/' + $scope.id);
  }

  //////////////////////////////////////////////////////////////////////
  // Functin Mapping
  //////////////////////////////////////////////////////////////////////
  $scope.reload = _reload;
  $scope.back = _back;

  //////////////////////////////////////////////////////////////////////
  // Method
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Loading data
  var _load = function() {

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    $q.all([
      _loadInstance(),
      _loadServerLog('web'),
      _loadServerLog('error'),
      _loadServerLog('access'),
      _loadServerLog('mt'),
    ])
    .then(function(){
      modal.close();
    })

  };

  //////////////////////////////////////////////////////////////////////
  // Loading instance data
  var _loadInstance = function() {

    return Instances.getInstance($routeParams.id).then(function(data){
      $scope.instance = data;
    });

  };

  //////////////////////////////////////////////////////////////////////
  // Loading server log
  var _loadServerLog = function(type) {

    var param = {
      logType: type
    };
    return Instances.getServerLog($routeParams.id, param).then(function(data){
      $scope.log[type] = data.messages;
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize
  //////////////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: ''
  };

  $scope.log = {};
  $scope.id = $routeParams.id;

  _load();
};

module.exports = serverLogController;
