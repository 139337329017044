'use strict';

AuthService.$inject = [
  '$http',
  'API'
];

function AuthService($http, API) {
  var signIn = function(username, password) {
    var url = API + '/login';
    var params = {
      username: username,
      password: password
    };

    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };
  return {
    signIn: function( username, password ) {
      return signIn(username, password);
    }
  };
};

module.exports = AuthService;
